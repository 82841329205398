import * as React from "react";
import {
  List,
  Datagrid,
  Create,
  SimpleForm,
  TextInput,
  ImageField,
  ImageInput,
  Edit,
  EditButton,
  FunctionField,
  DateInput,
  BooleanInput,
  NumberInput,
  Show,
  required,
  SelectInput,
  TabbedShowLayout,
  Tab,
  FormDataConsumer,
  minValue,
  SimpleShowLayout,
  BooleanField,
  DateField,
  ReferenceInput,
  TopToolbar,
  useRefresh,
  useTranslate,
  useEditController,
  useNotify,
  SearchInput,
  ShowButton,
} from "react-admin";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import RefreshIcon from "@mui/icons-material/Refresh";
import swal from "sweetalert";
import FormControlLabel from "@mui/material/FormControlLabel";
import AsyncSelect from "react-select/async/dist/react-select.esm";

import { TextField as RATextField } from "react-admin";
import { makeStyles } from "@mui/styles";
import { ListActions } from "../templates/ListActions";
import moment from "moment";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { HTTP } from "../../axios";
import { path, url, handleRequestResponse } from "../../request";
import Grid from "@mui/material/Grid";
import Swal from "sweetalert2";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import UpgradeOutlinedIcon from "@mui/icons-material/UpgradeOutlined";
import PrintIcon from "@mui/icons-material/Print";
import SaveIcon from "@mui/icons-material/Save";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useParams } from "react-router-dom";
import _, { toInteger } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Pagination } from "react-js-pagination";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import UpdateIcon from "@mui/icons-material/Update";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";

import Switch from "@mui/material/Switch";
import { Http, RollerShades } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import UploadImages from "../CustomComponents/UploadImage";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import PrintProvider, { Print, NoPrint } from "react-easy-print";
// import Page from "../CustomPages/SellReceipt/Page";
import LabTestVoucher from "../CustomPages/VoucherReceipt/LabTestVoucher";
import Page from "../CustomPages/SellReceipt/Page";
const role = localStorage.getItem("role") ?? "NoUser";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}

const uploadImage = async (event) => {
  const file = event.target.files[0];
  const base64 = await getBase64(file);
  return base64;
};

export const ListVisitor = (props) => {
  const [currency, setCurrency] = React.useState("default");
  const dollarPrice = React.useState(localStorage.getItem("dollarPrice" || 1));
  const translate = useTranslate();

  React.useEffect(() => {
    localStorage.removeItem("startDate");
    localStorage.removeItem("endDate");
  }, []);

  return (
    <List
      {...props}
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      sort={{ field: "id", order: "DESC" }}
      actions={
        <ListActions
          createNoPermission="true"
          setCurrency={setCurrency}
          currency={currency}
        />
      }
    >
      <Datagrid bulkActionButtons={<BulkAction {...props} />}>
        <RATextField source="id" label="resources.root.id" />
        {role.toLowerCase() === "laboratory" ||
        role.toLowerCase() === "xray" ? (
          <RATextField
            source="customerName"
            label="resources.root.customerName"
          />
        ) : (
          <FunctionField
            sortBy={"customerName"}
            label="resources.root.customerName"
            render={(record) => (
              <a
                href={`#/Customers/${record.customerId}/show`}
                target={`_blank`}
              >{`${record.customerName}`}</a>
            )}
          />
        )}

        <RATextField
          source="doctorDepartmentName"
          label="resources.root.department"
        />
        <RATextField
          source="doctorName"
          sortBy="doctorId"
          label="resources.root.doctorName"
        />
        {(role.toLowerCase() === "admin" ||
          role.toLowerCase() === "accountant" ||
          role.toLowerCase() === "superadmin") &&
          (currency === "default" ? (
            <FunctionField
              label="resources.root.visitPrice"
              sortBy={"mcSellPrice"}
              render={(record) =>
                record.payByMain
                  ? `$ ${record.mcSellPrice?.toLocaleString()}`
                  : `${record.scSellPrice?.toLocaleString()} ${translate(
                      "resources.root.iqd"
                    )}`
              }
            />
          ) : (
            <FunctionField
              label="resources.root.visitPrice"
              sortBy={"mcSellPrice"}
              render={(record) =>
                currency === "dollar"
                  ? `$ ${record.mcSellPrice?.toLocaleString()}`
                  : `${record.scSellPrice?.toLocaleString()} ${translate(
                      "resources.root.iqd"
                    )}`
              }
            />
          ))}
        {(role.toLowerCase() === "admin" ||
          role.toLowerCase() === "accountant" ||
          role.toLowerCase() === "superadmin") &&
          (currency === "default" ? (
            <FunctionField
              sortBy={"mcDiscountPrice"}
              label="resources.root.discount"
              render={(record) =>
                record.payByMain
                  ? `$ ${record.mcDiscountPrice?.toLocaleString()}`
                  : `${record.scDiscountPrice?.toLocaleString()} ${translate(
                      "resources.root.iqd"
                    )}`
              }
            />
          ) : (
            <FunctionField
              sortBy={"mcDiscountPrice"}
              label="resources.root.discount"
              render={(record) =>
                currency === "dollar"
                  ? `$ ${record.mcDiscountPrice?.toLocaleString()}`
                  : `${record.scDiscountPrice?.toLocaleString()} ${translate(
                      "resources.root.iqd"
                    )}`
              }
            />
          ))}
        {(role.toLowerCase() === "admin" ||
          role.toLowerCase() === "accountant" ||
          role.toLowerCase() === "superadmin") &&
          (currency === "default" ? (
            <FunctionField
              label="resources.root.totalPrice"
              render={(record) =>
                record.payByMain
                  ? `$ ${(
                      record.calculatedTotalPrice / parseFloat(dollarPrice)
                    )?.toLocaleString()}`
                  : `${record.calculatedTotalPrice?.toLocaleString()} ${translate(
                      "resources.root.iqd"
                    )}`
              }
            />
          ) : (
            <FunctionField
              label="resources.root.totalPrice"
              render={(record) =>
                currency === "dollar"
                  ? `$ ${(
                      record.calculatedTotalPrice / parseFloat(dollarPrice)
                    )?.toLocaleString()}`
                  : `${record.calculatedTotalPrice?.toLocaleString()} ${translate(
                      "resources.root.iqd"
                    )}`
              }
            />
          ))}

        <FunctionField
          label="resources.root.regular"
          sortBy={"feature1"}
          render={(record) =>
            record.feature1
              ? `${translate("resources.root.miner")}`
              : `${translate("resources.root.regular")}`
          }
        />
        <DateField
          source="date"
          label="resources.root.date"
          locales={"en-GB"}
        />
        <ShowButton />
        {/* <EditButton /> */}
      </Datagrid>
    </List>
  );
};

export const CreateVisitor = (props) => {
  const [doctors, setDoctors] = React.useState([]);
  const [doctor, setDoctor] = React.useState(null);
  const [filterdDoctors, setFilterdDoctors] = React.useState(null);
  const [customers, setCustomers] = React.useState([]);
  const [customer, setCustomer] = React.useState(null);
  const [value, setValue] = React.useState();
  const [visitPrice, setVisitPrice] = React.useState(0);
  const [note, setNote] = React.useState("");
  const [date, setDate] = React.useState(new Date());
  const [type, setType] = React.useState(false);
  const [visitNumber, setVisitNubmer] = React.useState(1);
  const [idemPotent, setIdemPotent] = React.useState(uuidv4());
  const [departments, setDepartments] = React.useState([]);
  const [selectedDepartment, setSelectedDepartment] = React.useState(null);
  const [latestVisits, setLatestVisits] = React.useState(null);

  React.useEffect(() => {
    HTTP.get("/customers?_end=2500")
      .then((response) => {
        setCustomers(response.data);
      })
      .catch((err) => handleRequestResponse(notify, err));
    HTTP.get("/Departments")
      .then((response) => {
        setDepartments(response.data);
      })
      .catch((err) => handleRequestResponse(notify, err));
    regetDoctors();
  }, [0]);
  const translate = useTranslate();
  const notify = useNotify();

  const customerChanged = (value) => {
    HTTP.get(`/CustomerVisits/GetVisitNumber/${value.id}`)
      .then((response) => {
        setVisitNubmer(response.data);
      })
      .catch((err) => handleRequestResponse(notify, err));
    setCustomer(value);
    setLatestVisits(null);
  };

  function submitTheVisit() {
    if (!customer) {
      notify("Select a patient");
      return;
    }
    if (!doctor) {
      notify("Select a doctor");
      return;
    }
    if (visitPrice == 0) {
      Swal.fire({
        title: translate(`resources.root.freeVisit`),
        showDenyButton: true,
        confirmButtonColor: "rgba(30, 111, 195, 1)",
        confirmButtonText: translate("resources.root.yes"),
        denyButtonText: translate("resources.root.No"),
      }).then((result) => {
        if (!result.isConfirmed) {
          return;
        }
      });
    }
    Swal.fire({
      title: translate(`resources.root.areYouSure`),
      showDenyButton: true,
      confirmButtonColor: "rgba(30, 111, 195, 1)",
      confirmButtonText: translate("resources.root.yes"),
      denyButtonText: translate("resources.root.No"),
    }).then((result) => {
      if (!result.isConfirmed) {
        return;
      }

      HTTP.post(`/CustomerVisits`, {
        doctorId: doctor.id,
        customerId: customer.id,
        date: date,
        payByMain: customer.payByMain,
        mcPrice: visitPrice,
        scPrice: visitPrice,
        note: note,
        feature1: type,
        number: visitNumber,
        idempotentToken: idemPotent,
      })
        .then((resp) => {
          notify("resources.root.success");
          setIdemPotent(uuidv4());
          setNote(null);
          setVisitPrice(0);
          setCustomer(null);
          setDoctor(null);
          // Reset it
        })
        .catch((err) =>
          notify("resources.root.someErrorOccurred", { type: "error" })
        )
        .catch((err) => handleRequestResponse(notify, err));
    });
  }

  const regetDoctors = () => {
    HTTP.get("/Doctors")
      .then((response) => {
        setDoctors(response.data);
        setFilterdDoctors(response.data);
        setSelectedDepartment(null);
      })
      .catch((err) => handleRequestResponse(notify, err));
  };

  const filterdDocter = (id) => {
    if (id === 0) setFilterdDoctors(doctors);
    else {
      const docFilter = doctors.filter((doctor) => {
        return doctor.departmentId === id;
      });
      setFilterdDoctors(docFilter);
    }
  };
  const filterhandler = (e) => {
    setSelectedDepartment(e.target.value);
    filterdDocter(e.target.value);
  };

  const getLatestVisits = () => {
    if (!customers) {
      notify(translate(`resources.root.customer`), { type: "error" });
      return;
    }
    HTTP.get(`/CustomerVisits?subLevelId=${customer.id}`)
      .then((response) => {
        setLatestVisits(response.data);
      })
      .catch((err) => handleRequestResponse(notify, err));
  };
  return (
    <main className="row m-4">
      <div className="col-12 col-lg-8">
        <div className="d-flex justify-content-between align-items-center">
          <h2>{translate("ra.navigation.Doctors")}</h2>
          <Box sx={{ minWidth: 240 }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="demo-simple-select-label">
                {translate(`resources.root.department`)}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label={translate(`resources.root.department`)}
                value={selectedDepartment}
                onChange={filterhandler}
              >
                <MenuItem value={0}>{translate("resources.root.all")}</MenuItem>
                {departments.map((department) => {
                  return (
                    <MenuItem key={department.id} value={department.id}>
                      {department.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Tooltip title={translate("resources.root.refresh")}>
            <IconButton onClick={regetDoctors} size="large">
              <RefreshIcon style={{ fontSize: "25px" }} />
            </IconButton>
          </Tooltip>
        </div>
        <hr className="hr" />
        <div
          className="row justify-content-center py-3"
          style={{ gap: "30px" }}
        >
          {filterdDoctors?.map((doctor22, index) => {
            return (
              <Card
                key={index}
                onClick={() => {
                  setDoctor(doctor22);
                  setVisitPrice(doctor22.scVisitPrice);
                }}
                className={`buttonHoverd col-5 col-md-2 text-center p-0  ${
                  doctor22.id === doctor?.id ? "glowBackground" : ""
                }`}
              >
                <CardMedia
                  component="img"
                  alt={doctor22.name}
                  obj
                  height="120"
                  image={
                    doctor22.attachment
                      ? `${path}${doctor22.attachment}`
                      : "https://via.placeholder.com/200"
                  }
                />
                <CardContent
                  style={{ height: "100px" }}
                  className="d-flex flex-column justify-content-between"
                >
                  <Typography gutterBottom variant="h6" component="h6">
                    {doctor22.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    className="bold"
                    // color="text.secondary"
                  >
                    {`${doctor22.scVisitPrice?.toLocaleString()} `}
                    {translate("resources.root.iqd")}
                  </Typography>
                </CardContent>
              </Card>
            );
          })}
        </div>
      </div>
      <div className="col-12 col-lg-4 d-flex flex-column">
        <section>
          <div className="d-flex flex-column justify-content-center align-content-center">
            <div className="row">
              <Autocomplete
                className="col-9"
                options={customers}
                variant="standard"
                autoHighlight
                getOptionLabel={(option) => option.fullName}
                value={value}
                onChange={(option, value) => {
                  customerChanged(value);
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      srcSet={
                        option.attachment
                          ? `${path}${option.attachment}`
                          : "https://via.placeholder.com/200"
                      }
                      src={
                        option.attachment
                          ? `${path}${option.attachment}`
                          : "https://via.placeholder.com/200"
                      }
                      alt=""
                    />
                    {option.fullName} - {option.phone}
                  </Box>
                )}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      variant="standard"
                      label={translate(`resources.root.choosePatient`)}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  );
                }}
              />
              <TextField
                className="col-3"
                id="outlined-basic"
                label={translate("resources.root.number")}
                type="number"
                defaultValue={1}
                value={visitNumber}
                variant="standard"
                onChange={(e) => {
                  setVisitNubmer(toInteger(e.target.value));
                }}
              />
            </div>
            <div className="card">
              {customer && (
                <div className="card-body">
                  <h5 className="card-title">{customer.fullName}</h5>
                  <h6 className="card-subtitle mb-2 text-muted">
                    {customer.phone}
                  </h6>
                  <div className="row">
                    <h6 className="col-12 d-flex justify-content-between">
                      <span>{moment(customer.dob).format("YYYY-MM-DD ")}</span>
                      <span>
                        {moment(new Date()).diff(customer.dob, "years")}&nbsp;
                        {translate("resources.root.year")}
                      </span>
                    </h6>
                    <h6 className="col-12">{customer.email}</h6>
                    <h6 className="col-12">{customer.address}</h6>
                  </div>
                </div>
              )}
            </div>
            {customer && (
              <div className="d-flex flex-column justify-content-between">
                <span
                  an
                  className="d-flex  justify-content-between align-items-center"
                >
                  <TextField
                    className="col-7"
                    id="outlined-basic"
                    label={`${translate(
                      "resources.root.price"
                    )} | ${visitPrice?.toLocaleString()} ${
                      customer.payByMain
                        ? translate("resources.root.usd")
                        : translate("resources.root.iqd")
                    }`}
                    defaultValue={0}
                    value={visitPrice}
                    onChange={(e) => {
                      setVisitPrice(e.target.value);
                    }}
                    variant="outlined"
                    type="number"
                  />

                  <InputGroup className="row col-5 justify-content-center">
                    <FormControlLabel
                      className="m-0 text-nowrap"
                      control={
                        <Switch
                          onChange={(e) => {
                            setType(e.target.checked);
                          }}
                          name="gilad"
                        />
                      }
                      label={translate("resources.root.miner")}
                    />
                  </InputGroup>
                </span>

                <TextField
                  id="outlined-basic"
                  label="Note"
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                  variant="outlined"
                />
              </div>
            )}
          </div>
        </section>

        <div>
          <Button
            variant="contained"
            fullWidth
            className="my-3"
            onClick={submitTheVisit}
          >
            {translate("resources.root.submit")}
          </Button>
        </div>

        {customer ? (
          <div className="d-flex flex-column text-center">
            <h4 className="text-center text-nowrap">
              {translate("resources.root.latestMedicine")}
            </h4>
            <Button
              variant="contained"
              color="success"
              fullWidth
              className="my-3"
              onClick={getLatestVisits}
            >
              {translate("resources.root.loadLatestVisits")}
            </Button>
            {latestVisits && (
              <div className="table100 ver2 m-b-110">
                <table data-vertable="ver2">
                  <thead>
                    <tr className="row100 head">
                      <th className="column100 column2">
                        {translate("resources.root.id")}
                      </th>
                      <th className="column100 column3">
                        {translate("resources.root.doctor")}
                      </th>

                      <th className="column100 column5">
                        {translate("resources.root.date")}
                      </th>

                      <th className="column100 column7">
                        {translate("resources.root.type")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {latestVisits.map((visit) => {
                      return (
                        <tr className="row100">
                          <td className="column100 column1">
                            <a
                              href={`/#/CustomerVisits/${visit.id}/show`}
                              target="_blank"
                            >
                              {visit.id}
                            </a>
                          </td>
                          <td className="column100 column2">
                            {visit.doctorName}
                          </td>

                          <td className="column100 column4">
                            {moment(visit.date).format("YYYY-MM-DD -- HH:MM")}
                          </td>

                          <td className="column100 column6">
                            {visit.feature1
                              ? translate("resources.root.miner")
                              : translate("resources.root.regular")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        ) : (
          <h4>Select a patient to continue</h4>
        )}
      </div>
    </main>
  );
};

export const ShowVisitor = (props) => {
  const params = useParams();
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles();
  const role = localStorage.getItem("role") ?? "NoUser";
  const [VisitedCustomer, setVisitedCustomer] = React.useState([]);
  const [customer, setCustomer] = React.useState([]);
  const [customerVisitMedItems, setCustomerVisitMedItems] = React.useState([]);
  const [customerVisitXRays, setCustomerVisitXRays] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [customerVisitProducts, setCustomerVisitProducts] = React.useState([]);
  const [customerName, setCustomerName] = React.useState([]);
  const [CustomerDob, setCustomerDob] = React.useState();
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [productNote, setProductNote] = React.useState(null);
  React.useEffect(() => {
    HTTP.get(`${url}/Products?_end=10000`).then((res) => {
      setProducts(res.data);
    });

    HTTP.get(`/CustomerVisits/${params.id}`).then((res) => {
      setCustomer(res.data);
    });
  }, []);

  React.useEffect(() => {
    HTTP.get(`/CustomerVisits/${params.id}`).then((response) => {
      setCustomerVisitProducts(response.data.customerVisitProducts);
      setCustomerVisitMedItems(response.data.customerVisitMedItems);
      setCustomerVisitXRays(response.data.customerVisitXRays);
      setCustomerDob(response.data.customerDob);
      setCustomerName(response.data.customerName);
      setCustomer(response.data);
      {
        role.toLowerCase() === "admin" ||
        role.toLowerCase() === "accountant" ||
        role.toLowerCase() === "superadmin"
          ? setVisitedCustomer([
              { title: "name", data: response.data.customerName },
              { title: "age", data: response.data.customerAge },
              { title: "number", data: response.data.customerPhone },
              { title: "doctorName", data: response.data.doctorName },
              {
                title: "department",
                data: response.data?.doctorDepartmentName,
              },
              { title: "invoiceNumber", data: response.data?.invoiceNumber },
              {
                title: "visitDate",
                data: moment(response.data.date).format("YYYY-MM-DD, HH:MM"),
              },
              {
                title: "isMiner",
                data: `${response.data.feature1 ? "Yes" : "No"}`,
              },
              { title: "description", data: response.data.description },
              { title: "note", data: response.data.note },
            ])
          : setVisitedCustomer([
              { title: "name", data: response.data.customerName },
              { title: "age", data: response.data.customerAge },
              { title: "doctorName", data: response.data.doctorName },
              {
                title: "department",
                data: response.data?.doctorDepartmentName,
              },
              { title: "invoiceNumber", data: response.data?.invoiceNumber },
              {
                title: "visitDate",
                data: moment(response.data.date).format("YYYY-MM-DD, HH:MM"),
              },
              {
                title: "isMiner",
                data: `${response.data.feature1 ? "Yes" : "No"}`,
              },
              { title: "description", data: response.data.description },
              { title: "note", data: response.data.note },
            ]);
      }
    });
  }, []);

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/Products?_end=100&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((product) => {
        newArray.push({
          value: product.id,
          label: product.name,
          product: product,
        });
      });
      callback(newArray);
    }
  };

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label={"resources.root.generalInfo"}>
          {VisitedCustomer && (
            <Grid
              container
              spacing={3}
              style={{ padding: "0px !important" }}
              mt={2}
              justifyContent={"center"}
            >
              {VisitedCustomer.map((customerData) => {
                return (
                  customerData.data && (
                    <Grid item className="col-12 col-md-6 col-lg-4">
                      <Card style={{ textAlign: "center" }} className="m-0">
                        <CardContent className="m-0 p-0">
                          <Row className="newSellReturnBox-dashboard">
                            <Col xs={12} className="pt-2 h5">
                              {translate(
                                `resources.root.${customerData.title}`
                              )}
                            </Col>
                            <Col
                              className="blured py-3"
                              style={{ fontSize: "1.2rem" }}
                              xs={12}
                            >
                              <Typography className="text-nowrap h6 d-flex justify-content-around">
                                {customerData.data}
                              </Typography>
                            </Col>
                          </Row>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                );
              })}
            </Grid>
          )}
        </Tab>

        {role.toLocaleLowerCase() !== "xray" &&
          role.toLocaleLowerCase() !== "laboratory" && (
            <Tab label="resources.root.medItems">
              <PrintProvider>
                {customerVisitMedItems.length > 0 ? (
                  <NoPrint>
                    <div className="d-flex justify-content-around align-items-center border p-3 my-3 rounded-3">
                      <h3>
                        {customerName}&nbsp;-&nbsp;
                        {moment(new Date()).diff(CustomerDob, "years")}
                        &nbsp;
                        {translate("resources.root.years")}
                      </h3>
                      <PrintIcon
                        style={{ fontSize: 40, cursor: "pointer" }}
                        className="upgradeButton btn btn-primary p-0"
                        onClick={() => {
                          let sidebar =
                            document.getElementsByClassName("MuiDrawer-root");
                          sidebar[0].style.display = "none";
                          window.print();
                          sidebar[0].style.display = "block";
                        }}
                      >
                        {translate("resources.root.print")}
                      </PrintIcon>
                    </div>

                    {customerVisitMedItems.length > 0 && (
                      <div className="table100 ver2 m-b-110 my-4">
                        <table data-vertable="ver2">
                          <thead>
                            <tr className="row100 head">
                              <th className="column100 column1">
                                {translate("resources.root.name")}
                              </th>
                              <th className="column100 column2">
                                {translate("resources.root.price")}
                              </th>
                              <th className="column100 column3">
                                {translate("resources.root.note")}
                              </th>
                              <th className="column100 column4">
                                {translate("resources.root.action")}
                              </th>
                            </tr>
                          </thead>
                          {customerVisitMedItems.map((product) => {
                            return (
                              <tr className="row100">
                                <td className="column100 column1">
                                  {product.medItemName}
                                </td>
                                <td className="column100 column2">
                                  {product.scSellPrice?.toLocaleString()}
                                </td>
                                <td className="column100 column3">
                                  {product.note}
                                </td>
                                <td className="column100 column4">
                                  <IconButton
                                    aria-label="resources.root.delete"
                                    onClick={() => {
                                      swal({
                                        title: translate(
                                          "resources.root.makeSure"
                                        ),
                                        text: translate(
                                          "resources.root.confirmPerminantDelete"
                                        ),
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: true,
                                      }).then((willDelete) => {
                                        if (willDelete) {
                                          HTTP.delete(
                                            `/CustomerVisitMedItems/${product.id}`
                                          )
                                            .then((res) => {
                                              const remainItems =
                                                customerVisitMedItems.filter(
                                                  (el) => el.id !== product.id
                                                );
                                              setCustomerVisitMedItems(
                                                remainItems
                                              );
                                            })
                                            .catch((err) =>
                                              handleRequestResponse(notify, err)
                                            );
                                        } else {
                                          swal(
                                            translate(
                                              "resources.root.deleteCancel"
                                            )
                                          );
                                        }
                                      });
                                    }}
                                  >
                                    <DeleteIcon style={{ fontSize: "30px" }} />
                                  </IconButton>
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                    )}
                  </NoPrint>
                ) : (
                  <h3 className="text-center">
                    {translate("resources.root.noMeditem")}
                  </h3>
                )}
                <Print single={true} name="foo">
                  <div className={classes.printWrapper}>
                    <Page
                      invoice={customer}
                      title={translate("resources.root.medItems")}
                    />
                  </div>
                </Print>
              </PrintProvider>
            </Tab>
          )}
        {role.toLocaleLowerCase() !== "xray" && (
          <Tab label="resources.root.labTests">
            <PrintProvider>
              <Print single={true} name="foo">
                <div className={classes.printWrapper}>
                  <Page
                    invoice={customer}
                    title={translate("resources.root.labTests")}
                  />
                </div>
              </Print>
              {customer.customerVisitLabTests?.length > 0 ? (
                <NoPrint force>
                  <div className="d-flex justify-content-around align-items-center border p-3 my-3 rounded-3">
                    <h3>
                      {customerName}&nbsp;-&nbsp;
                      {moment(new Date()).diff(CustomerDob, "years")}
                      &nbsp;
                      {translate("resources.root.years")}
                    </h3>
                    <PrintIcon
                      style={{ fontSize: 40, cursor: "pointer" }}
                      className="upgradeButton btn btn-primary p-0"
                      onClick={() => {
                        let sidebar =
                          document.getElementsByClassName("MuiDrawer-root");
                        sidebar[0].style.display = "none";
                        window.print();
                        sidebar[0].style.display = "block";
                      }}
                    >
                      {translate("resources.root.print")}
                    </PrintIcon>
                  </div>
                  {customer.customerVisitLabTests?.map((labTest, index) => {
                    return (
                      <SimpleShowLayout
                        className="border-bottom my-2"
                        key={index}
                      >
                        <h4>
                          {index + 1}- {labTest.labTestName}
                        </h4>
                        <div className="row w-100 justify-content-center align-items-center">
                          <Grid
                            className="col-12 col-md-8"
                            container
                            spacing={2}
                            direction="row"
                            justifyContent={"space-between"}
                            alignItems={"stretch"}
                          >
                            <TextField
                              className="w-25"
                              label={translate("resources.root.result")}
                              type="text"
                              defaultValue={labTest.condition}
                              variant="filled"
                              onChange={(e) =>
                                (labTest.condition = e.target.value)
                              }
                            />
                            <TextField
                              className="w-25"
                              label={translate("resources.root.note")}
                              type="text"
                              defaultValue={labTest.note}
                              variant="filled"
                              onChange={(e) => (labTest.note = e.target.value)}
                            />

                            <textarea
                              cols={30}
                              rows={3}
                              className="border-0"
                              disabled
                            >
                              {labTest.labTestNote}
                            </textarea>

                            <div className="d-flex justify-content-between align-items-center ">
                              {labTest.condition && (
                                <CheckCircleSharpIcon
                                  style={{ fontSize: 40, cursor: "pointer" }}
                                  className="upgradeButton btn btn-success p-0 mx-1"
                                ></CheckCircleSharpIcon>
                              )}{" "}
                              <UpdateIcon
                                style={{ fontSize: 40, cursor: "pointer" }}
                                className="upgradeButton btn btn-primary p-0 mx-1"
                                id={`saveButton`}
                                onClick={() => {
                                  Swal.fire({
                                    title: translate(
                                      `resources.root.sureUpdate`
                                    ),
                                    showDenyButton: true,
                                    confirmButtonColor: "rgba(30, 111, 195, 1)",
                                    confirmButtonText:
                                      translate("resources.root.yes"),
                                    denyButtonText:
                                      translate("resources.root.No"),
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      HTTP.put(
                                        `/CustomerVisitLabTests/${labTest.id}`,
                                        labTest
                                      )
                                        .then((res) => {
                                          notify("Success ", {
                                            type: "success",
                                          });
                                        })
                                        .catch((err) =>
                                          handleRequestResponse(notify, err)
                                        );
                                    } else {
                                      return;
                                    }
                                  });
                                }}
                              ></UpdateIcon>
                            </div>
                            <Col className="col-12 p-0 my-3">
                              <Button
                                className="imageButton btn-block"
                                color="primary"
                                variant="contained"
                                component="span"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  fill="currentColor"
                                  className="bi bi-upload mx-2 "
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                  <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                                </svg>
                                {translate("resources.root.uploadImg")}
                                <input
                                  className=""
                                  type="file"
                                  accept="image/*"
                                  onChange={async (e) => {
                                    const file = e.target.files[0];
                                    const base64 = await getBase64(file);

                                    labTest.attachment = base64;
                                  }}
                                />
                              </Button>
                            </Col>
                          </Grid>
                          {labTest?.attachment && (
                            <div className="my-4 w-50 mx-auto col-12 col-md-4">
                              <a
                                href={path + labTest?.attachment}
                                target="_blank"
                              >
                                <img
                                  className="w-100"
                                  src={path + labTest?.attachment}
                                  style={{
                                    objectFit: "scale-down",
                                    cursor: "pointer",
                                    borderRadius: "1rem",
                                  }}
                                />
                              </a>
                            </div>
                          )}
                        </div>
                      </SimpleShowLayout>
                    );
                  })}
                </NoPrint>
              ) : (
                <h3 className="text-center">
                  {translate("resources.root.noLabTest")}
                </h3>
              )}
            </PrintProvider>
          </Tab>
        )}

        {role.toLocaleLowerCase() !== "laboratory" && (
          <Tab label="resources.root.XRays">
            <PrintProvider>
              <Print single={true} name="foo">
                <div className={classes.printWrapper}>
                  {/* <LabTestVoucher
                      data={{
                        type: "xRay",
                        id: xRay?.xRayId,
                        date: customer?.date,
                        customerName: customer?.customerName,
                        customerAge: customer?.customerAge,
                        customerMale: customer?.customerMale,
                        doctorName: customer?.doctorName,
                        doctorDepartmentName: customer?.doctorDepartmentName,
                        scSellPrice: customer?.scSellPrice,
                        testName: xRay?.xRayName,
                        findings: xRay?.findings,
                        impression: xRay?.impression,
                        recommendation: xRay?.recommendation,
                        tags: xRay?.tags,
                        note: xRay?.note,
                      }}
                    />
                     */}
                  <Page
                    invoice={customer}
                    title={translate("resources.root.XRays")}
                  />
                </div>
              </Print>
              {customerVisitXRays.length > 0 ? (
                <NoPrint>
                  <div className="d-flex justify-content-around align-items-center border p-3 my-3 rounded-3">
                    <h3>
                      {customerName}&nbsp;-&nbsp;
                      {moment(new Date()).diff(CustomerDob, "years")}
                      &nbsp;
                      {translate("resources.root.years")}
                    </h3>
                    {}
                    <PrintIcon
                      style={{ fontSize: 40, cursor: "pointer" }}
                      className="upgradeButton btn btn-primary p-0"
                      onClick={() => {
                        let sidebar =
                          document.getElementsByClassName("MuiDrawer-root");
                        sidebar[0].style.display = "none";
                        window.print();
                        sidebar[0].style.display = "block";
                      }}
                    >
                      {translate("resources.root.print")}
                    </PrintIcon>
                  </div>
                  {customerVisitXRays.map((xRay, index) => {
                    return (
                      <SimpleShowLayout
                        className="border-bottom my-2"
                        key={index}
                      >
                        <h4>
                          {index + 1}- {xRay.xRayName}
                        </h4>
                        <div className="row w-100 justify-content-center align-items-center">
                          <Row className="justify-content-between align-items-center col-12 col-md-9">
                            <Col className="row col-11 justify-content-between align-items-center">
                              <Col className="col-12 col-md-5 p-0 my-3">
                                <TextField
                                  fullWidth
                                  label={translate("resources.root.note")}
                                  type="text"
                                  defaultValue={xRay.note}
                                  variant="filled"
                                  onChange={(e) => (xRay.note = e.target.value)}
                                />
                              </Col>
                              <Col className="col-12 col-md-5 p-0 my-3">
                                <TextField
                                  fullWidth
                                  label={translate("resources.root.impression")}
                                  type="text"
                                  defaultValue={xRay.impression}
                                  variant="filled"
                                  onChange={(e) =>
                                    (xRay.impression = e.target.value)
                                  }
                                />
                              </Col>
                              <Col className="col-12 col-md-5 p-0 my-3">
                                <TextField
                                  fullWidth
                                  label={translate("resources.root.findings")}
                                  type="text"
                                  defaultValue={xRay.findings}
                                  variant="filled"
                                  onChange={(e) =>
                                    (xRay.findings = e.target.value)
                                  }
                                />
                              </Col>
                              <Col className="col-12 col-md-5 p-0 my-3">
                                <TextField
                                  fullWidth
                                  label={translate(
                                    "resources.root.recommendation"
                                  )}
                                  type="text"
                                  defaultValue={xRay.recommendation}
                                  variant="filled"
                                  onChange={(e) =>
                                    (xRay.recommendation = e.target.value)
                                  }
                                />
                              </Col>
                              <Col className="col-12 col-md-5 p-0 my-3">
                                <TextField
                                  fullWidth
                                  label={translate("resources.root.tags")}
                                  type="text"
                                  defaultValue={xRay.tags}
                                  variant="filled"
                                  onChange={(e) => (xRay.tags = e.target.value)}
                                />
                              </Col>
                              <Col className="col-12 col-md-5 p-0 my-3">
                                <Button
                                  className="imageButton btn-block"
                                  color="primary"
                                  variant="contained"
                                  component="span"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    fill="currentColor"
                                    className="bi bi-upload mx-2 "
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                                  </svg>
                                  {translate("resources.root.uploadImg")}
                                  <input
                                    className=""
                                    type="file"
                                    accept="image/*"
                                    onChange={async (e) => {
                                      const file = e.target.files[0];
                                      const base64 = await getBase64(file);

                                      xRay.attachment = base64;
                                    }}
                                  />
                                </Button>
                              </Col>
                              {/* <LabTestVoucher /> */}
                            </Col>

                            <Col className="col-1 d-flex align-items-center justify-content-between m-0 p-0">
                              <UpdateIcon
                                style={{ fontSize: 40, cursor: "pointer" }}
                                className="upgradeButton btn btn-primary p-0"
                                id={`saveButton`}
                                onClick={() => {
                                  Swal.fire({
                                    title: translate(
                                      `resources.root.sureUpdate`
                                    ),
                                    showDenyButton: true,
                                    confirmButtonColor: "rgba(30, 111, 195, 1)",
                                    confirmButtonText:
                                      translate("resources.root.yes"),
                                    denyButtonText:
                                      translate("resources.root.No"),
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      HTTP.put(
                                        `/CustomerVisitXRays/${xRay.id}`,
                                        xRay
                                      )
                                        .then((res) => {
                                          notify("Success ", {
                                            type: "success",
                                          });
                                        })
                                        .catch((err) =>
                                          handleRequestResponse(notify, err)
                                        );
                                    } else {
                                      return;
                                    }
                                  });
                                }}
                              >
                                {/* <SaveIcon style={{ fontSize: "30px" }} /> */}
                              </UpdateIcon>
                            </Col>
                          </Row>
                          {xRay?.attachment && (
                            <div className="my-4 w-50 mx-auto col-12 col-md-3">
                              <a href={path + xRay?.attachment} target="_blank">
                                <img
                                  className="w-100"
                                  src={path + xRay?.attachment}
                                  style={{
                                    objectFit: "scale-down",
                                    cursor: "pointer",
                                    borderRadius: "1rem",
                                  }}
                                />
                              </a>
                            </div>
                          )}
                        </div>
                      </SimpleShowLayout>
                    );
                  })}
                </NoPrint>
              ) : (
                <h3 className="text-center">
                  {translate("resources.root.noXRay")}
                </h3>
              )}
            </PrintProvider>
          </Tab>
        )}
        {role.toLocaleLowerCase() !== "xray" &&
          role.toLocaleLowerCase() !== "laboratory" && (
            <Tab label="resources.root.medicines" className="h-100">
              <PrintProvider>
                <Print single={true} name="foo">
                  <div className={classes.printWrapper}>
                    <Page
                      invoice={customer}
                      title={translate("resources.root.medicines")}
                    />
                  </div>
                </Print>
                <NoPrint>
                  <Row>
                    <Col xs={12}>
                      <div className="d-flex justify-content-around align-items-center border p-3 my-3 rounded-3">
                        <h3>
                          {customerName}&nbsp;-&nbsp;
                          {moment(new Date()).diff(CustomerDob, "years")}
                          &nbsp;
                          {translate("resources.root.years")}
                        </h3>
                        <PrintIcon
                          style={{ fontSize: 40, cursor: "pointer" }}
                          className="upgradeButton btn btn-primary p-0"
                          onClick={() => {
                            let sidebar =
                              document.getElementsByClassName("MuiDrawer-root");
                            sidebar[0].style.display = "none";
                            window.print();
                            sidebar[0].style.display = "block";
                          }}
                        >
                          {translate("resources.root.print")}
                        </PrintIcon>
                      </div>
                      <SimpleShowLayout
                        className="border-bottom my-2"
                        key={uuidv4()}
                      >
                        <Row className="justify-content-between align-items-center">
                          <Col className="row col-11 justify-content-between align-items-center">
                            <Col className="flex-1 col-12 col-lg-6 d-flex align-items-center justify-content-start m-0 my-1 px-3">
                              <p className="m-0 mr-2 text-nowrap">
                                {translate("resources.root.chooseProduct")} :
                              </p>
                              {localStorage.getItem("useOldSelect") ===
                              "true" ? (
                                <Autocomplete
                                  id="combo-box-demo"
                                  className="w-100"
                                  options={products}
                                  value={selectedProduct}
                                  getOptionLabel={(option) =>
                                    `${option.name} - ${option.barcode}`
                                  }
                                  onChange={(el, val) => {
                                    setSelectedProduct(val);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={translate(
                                        "resources.root.chooseProduct"
                                      )}
                                      variant="outlined"
                                    />
                                  )}
                                />
                              ) : (
                                <AsyncSelect
                                  defaultOptions
                                  className="w-100"
                                  loadOptions={loadOptions}
                                  onChange={(e) => {
                                    setSelectedProduct(e ? e.value : 0);
                                  }}
                                />
                              )}
                            </Col>
                            <Col className="col-12 col-md-6 p-0 my-1">
                              <TextField
                                fullWidth
                                label={translate("resources.root.note")}
                                type="text"
                                defaultValue={productNote}
                                variant="filled"
                                onBlur={(e) => setProductNote(e.target.value)}
                              />
                            </Col>
                          </Col>
                          <Col className="col-1 d-flex align-items-center justify-content-center my-1 p-0">
                            <AddIcon
                              style={{ fontSize: 40, cursor: "pointer" }}
                              className="upgradeButton btn btn-primary p-0"
                              id={`saveButton`}
                              aria-label={translate(
                                "resources.root.saveButton"
                              )}
                              onClick={async (e) => {
                                if (!selectedProduct) {
                                  notify("Please Select Product", {
                                    type: "error",
                                  });
                                  return;
                                }
                                HTTP.post(`/CustomerVisitProducts`, {
                                  customerVisitId: toInteger(params.id),
                                  productId: selectedProduct.id,
                                  productName: selectedProduct.name,
                                  productBarcode: selectedProduct.barcode,
                                  note: productNote,
                                })
                                  .then((res) => {
                                    const remainItems =
                                      customerVisitProducts.filter(
                                        (el) => el.id !== 0
                                      );
                                    setCustomerVisitProducts([
                                      res.data,
                                      ...remainItems,
                                    ]);
                                    setProductNote(null);
                                    setSelectedProduct(null);
                                    notify(translate("resources.root.added"), {
                                      type: "success",
                                    });
                                  })
                                  .catch((err) =>
                                    handleRequestResponse(notify, err)
                                  );
                              }}
                            >
                              <SaveIcon style={{ fontSize: "30px" }} />
                            </AddIcon>
                          </Col>
                        </Row>
                      </SimpleShowLayout>
                      {customerVisitProducts.length > 0 ? (
                        <div className="table100 ver2 m-b-110">
                          <table data-vertable="ver2">
                            <thead>
                              <tr className="row100 head">
                                <th className="column100 column1">
                                  {translate("resources.root.barcode")}
                                </th>
                                <th className="column100 column2">
                                  {translate("resources.root.name")}
                                </th>
                                <th className="column100 column3">
                                  {translate("resources.root.note")}
                                </th>
                                <th className="column100 column4">
                                  {translate("resources.root.action")}
                                </th>
                              </tr>
                            </thead>
                            {customerVisitProducts.map((product) => {
                              return (
                                <tr className="row100">
                                  <td className="column100 column1">
                                    {product.productBarcode}
                                  </td>
                                  <td className="column100 column2">
                                    {product.productName}
                                  </td>
                                  <td className="column100 column3">
                                    {product.note}
                                  </td>
                                  <td className="column100 column4">
                                    <IconButton
                                      aria-label="resources.root.delete"
                                      onClick={() => {
                                        swal({
                                          title: translate(
                                            "resources.root.makeSure"
                                          ),
                                          text: translate(
                                            "resources.root.confirmPerminantDelete"
                                          ),
                                          icon: "warning",
                                          buttons: true,
                                          dangerMode: true,
                                        }).then((willDelete) => {
                                          if (willDelete) {
                                            HTTP.delete(
                                              `/CustomerVisitProducts/${product.id}`
                                            )
                                              .then((res) => {
                                                const remainItems =
                                                  customerVisitProducts.filter(
                                                    (el) => el.id !== product.id
                                                  );
                                                setCustomerVisitProducts(
                                                  remainItems
                                                );
                                              })
                                              .catch((err) =>
                                                handleRequestResponse(
                                                  notify,
                                                  err
                                                )
                                              );
                                          } else {
                                            swal(
                                              translate(
                                                "resources.root.deleteCancel"
                                              )
                                            );
                                          }
                                        });
                                      }}
                                    >
                                      <DeleteIcon
                                        style={{ fontSize: "30px" }}
                                      />
                                    </IconButton>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      ) : (
                        <h3
                          className="text-center"
                          style={{ marginBottom: "250px" }}
                        >
                          {translate("resources.root.noMedicines")}
                        </h3>
                      )}
                    </Col>
                  </Row>
                </NoPrint>
              </PrintProvider>
            </Tab>
          )}
      </TabbedShowLayout>
    </Show>
  );
};
const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
  },
  formCol: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  input: {
    width: "70%",
  },
  table: {
    minWidth: 500,
    marginTop: 20,
  },
  TableHead: {
    backgroundColor: "#111",
  },
  TableHeadCell: {
    color: "#fff",
  },
  addStoreSection: {
    display: "flex",
    justifyContent: "space-between",
    width: 500,
  },
  printWrapper: {
    display: "none",
    width: "100%",
    height: "950",
    ["@media print"]: {
      display: "block",
      width: "100%",
    },
  },
});
